import dynamic from 'next/dynamic'
import bannerConfigAggregator from './banner/config-aggregator'
import BannerMenuShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu-shimmer'
import BannerTextShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-text/banner-text-shimmer'
import BannerEmotionalShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-emotional/banner-emotional-shimmer'
import BannerBgTextShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-bg-text/banner-bg-text-shimmer'
import BannerProductShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-product/banner-product-shimmer'
import BannerProductColorRelatedShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-product-color-related/banner-product-color-related-shimmer'
import BannerTileShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-tile/banner-tile-shimmer'

const BannerMenu = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu'
		),
	{ loading: BannerMenuShimmer }
)

const BannerText = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-text/banner-text'
		),
	{ loading: BannerTextShimmer }
)

const BannerEmotional = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-emotional/banner-emotional'
		),
	{ loading: BannerEmotionalShimmer }
)

const BannerBgText = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-bg-text/banner-bg-text'
		),
	{ loading: BannerBgTextShimmer }
)

const BannerProduct = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-product/banner-product'
		),
	{ loading: BannerProductShimmer }
)

const BannerProductColorRelated = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-product-color-related/banner-product-color-related'
		),
	{ loading: BannerProductColorRelatedShimmer }
)

const BannerTile = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-tile/banner-tile'
		),
	{ loading: BannerTileShimmer }
)

const bannerMenuConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerMenu,
}

const bannerTextConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerText,
}

const bannerEmotionalConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerEmotional,
}

const bannerBgTextConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerBgText,
}

const bannerProductConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerProduct,
}

const bannerProductColorRelatedConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerProductColorRelated,
}

const bannerTileConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerTile,
}

const customContentTypes = {
	'banner-menu': bannerMenuConfig,
	'banner-text': bannerTextConfig,
	'banner-bg-text': bannerBgTextConfig,
	'banner-product': bannerProductConfig,
	'banner-product-color-related': bannerProductColorRelatedConfig,
	'slide-emotional': bannerEmotionalConfig,
	'banner-tile': bannerTileConfig,
}

export default customContentTypes
